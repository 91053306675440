<template>
  <div class="main-chart" :class="isMobile ? 'mobile' : ''">
    <div id="mainChart"></div>
    <div class="filter-section">
      <div class="switch-options">
        <template v-for="(item, key) in reportType">
          <button
            :key="key"
            class="switch-item"
            :class="{'active': selectedRange === item.value}"
            @click="handleChangeSelectedRange(item.value)">{{ item.label }}</button>
        </template>
      </div>
    </div>


    <div class="chart-type">
      <div class="switch-options">
        <template v-for="(item, key) in chartTypeOptions">
          <button
            :key="key"
            class="switch-item"
            :class="{'active': chartType === item.value}"
            @click="handleChangeChartType(item.value)">{{ item.label }}</button>
        </template>
      </div>
    </div>

    <div v-if="isZoomed" class="reset-zoom-btn" @click="handleResetZoom">
      <custom-icon icon="SEARCH" color-code="#fff" height="15px" width="15px" />
    </div>
  </div>
</template>

<script>
  import Highcharts from 'highcharts'
  import moment from 'moment-jalaali'
  import {
    getReceivesDetailsChart,
    getSaleAddonDetailsChart,
    getTimeDetailsChart
  } from "../../../../http/requests/reports";
  import {addComma, hideLoading, showLoading, customParseFloat} from "../../../../assets/js/functions";
  import CustomIcon from "@/components/customIcon/customIcon.vue";

  export default {
    name: "mainChart",
    components: {CustomIcon},
    data () {
      return {
        isZoomed: false,
        data: [],
        grossProfiteData: [],
        labels: [],
        selectedRange: 1,
        rangeType: '',
        chartType: 1,
        chartInfo: {},
        reportType: [
          {
            label: this.$t('report.charts.main.labels.daily'),
            value: 1
          },
          {
            label: this.$t('report.charts.main.labels.weekly'),
            value: 2
          },
          {
            label: this.$t('report.charts.main.labels.yearly'),
            value: 3
          },
        ],
        presentTimeLabels: {
          1: this.$t('report.charts.main.labels.today'),
          2: this.$t('report.charts.main.labels.week'),
          3: this.$t('report.charts.main.labels.year'),
        },
        pastTimeLabels: {
          1: this.$t('report.charts.main.labels.yesterday'),
          2: this.$t('report.charts.main.labels.lastWeek'),
          3: this.$t('report.charts.main.labels.lastYear'),
        },
        chartTypeOptions: [
          {
            label: this.$t('report.charts.main.labels.reserve'),
            value: 1
          },
        ]
      }
    },

    computed: {
      isMobile () {
        return this.$store.state.windowWidth < this.$store.state.mobileScreen
      },
    },

    created () {
      this.getReceiveDetails()
    },

    methods: {
      getReceiveDetails () {
        let filters = []
        if (this.rangeType.length > 0) {
          filters.push(this.rangeType)
        }
        switch (this.chartType) {
          case 1:
            showLoading()
            getTimeDetailsChart(filters).then((response) => {
              const chartData = response.data

              this.data = []
              this.grossProfiteData = []
              this.labels = []

              for (let i = 0; i < chartData.total_sale.length; i++) {
                let label = chartData.labels[i]
                this.labels.push(chartData.labels[i])
                this.data.push([label, customParseFloat(chartData.total_sale[i])])
              }

              if (chartData.gross_profit !== null) {
                for (let i = 0; i < chartData.gross_profit.length; i++) {
                  const label = chartData.labels[i]
                  this.grossProfiteData.push([label, customParseFloat(chartData.gross_profit[i])])
                }
              }
              this.showChart()
              hideLoading()
            })
              .catch(() => {
                hideLoading()
              })
            break

          case 2:
            showLoading()
            getSaleAddonDetailsChart(filters).then((response) => {
              const chartData = response.data
              this.data = []
              this.pastData = []
              this.labels = []
              for (let i = 0; i < chartData.current_period_values.length; i++) {
                let label = chartData.labels[i]
                this.labels.push(chartData.labels[i])
                this.data.push([label, parseInt(chartData.current_period_values[i])])
                this.pastData.push([label, parseInt(chartData.past_period_values[i])])
              }
              this.showChart()
              hideLoading()
            })
              .catch(() => {
                hideLoading()
              })
            break

          case 3:
            showLoading()
            getReceivesDetailsChart(filters).then((response) => {
              const chartData = response.data
              this.data = []
              this.pastData = []
              this.labels = []
              for (let i = 0; i < chartData.current_period_values.length; i++) {
                let label = chartData.labels[i]
                this.labels.push(chartData.labels[i])
                this.data.push([label, parseInt(chartData.current_period_values[i])])
                this.pastData.push([label, parseInt(chartData.past_period_values[i])])
              }
              this.showChart()
              hideLoading()
            })
              .catch(() => {
                hideLoading()
              })
            break
        }
      },
      showChart () {
        Highcharts.setOptions({
          lang: {
            resetZoom: this.$t('report.charts.main.labels.default'),
            drillUpText: 'BACK',
            useHTML: true
          },
          rtlFix: true,
          chart: {
            style: {
              direction: 'ltr',
              fontFamily: 'iransans'
            }
          },
          xAxis: {
            title: {
              useHTML: true
            },
            labels: {
              useHTML: true
            }
          },
          yAxis: {
            title: {
              useHTML: true,
              style: {
                whiteSpace: 'nowrap'
              }
            },
            minPadding: 0,
            maxPadding: 0,
            softMax: 10,
          },
          legend: {
            useHTML: true
          },
          credits: {
            enabled: false
          },
          tooltip: {
            // headerFormat: '<span style="font-size: 0.8em">{point.key}</span><br/>',
            style: {
              // direction: 'rtl',
              // right: '0',
              fontSize: '1em'
            }
          },
        });

        let chartOptions = {
          chart: {
            renderTo: 'mainChart',
            zoomType: 'x',
            events: {
              selection: (e) => {
                if (e.resetSelection) {
                  this.isZoomed = false;
                } else {
                  this.isZoomed = true;
                  this.$nextTick(() => {
                    const resetZoomBtn = document.querySelector('.highcharts-reset-zoom')
                    if (resetZoomBtn) {
                      resetZoomBtn.style.display = 'none'
                    }
                  })
                }
              }
            },
            resetZoomButton: {
              position: {
                x: 0,
                y: -10
              }
            }
          },
          title: {
            text: ''
          },
          // subtitle: {
          //   text: document.ontouchstart === undefined ?
          //     'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
          // },
          xAxis: {
            type: 'datetime',
            categories: this.labels
          },
          yAxis: {
            title: {
              text: this.$t('report.charts.main.labels.price', {currency: this.$t('currency')})
            },
            labels: {
              formatter: function() {
                return '<span style="direction: ltr;">' + addComma(this.value) + '</span>';
              }
            },
          },
          legend: {
            enabled: this.grossProfiteData.length > 0
          },
          plotOptions: {
            areaspline: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [0, '#48B4E0'],
                  [1, Highcharts.color('#48B4E0').setOpacity(0).get('rgba')]
                ]
              },
              marker: {
                radius: 2
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1
                }
              },
              threshold: null
            }
          },
          tooltip: {
            formatter () {
              return '<b>' + `${addComma(this.y, true) || 0}` + '</b>'
            }
          },

          series: [
            {
              type: 'areaspline',
              name: this.$t('report.charts.main.labels.grossProfit'),
              data: this.grossProfiteData,
              color: '#03ade9',
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [0, '#03ade9'],
                  [1, Highcharts.color('#03ade9').setOpacity(0).get('rgba')]
                ]
              },
            },
            {
              type: 'areaspline',
              name: this.$t('report.charts.main.labels.totalSale'),
              data: this.data,
              color: '#aaaaaa',
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [0, '#aaaaaa'],
                  [1, Highcharts.color('#aaaaaa').setOpacity(0).get('rgba')]
                ]
              },
            }
          ]
        }
        if (this.grossProfiteData.length === 0) {
          chartOptions.series.splice(0, 1)
        }
        this.chartInfo = Highcharts.chart(chartOptions)
      },

      handleChangeChartType (value) {
        this.chartType = value
        this.getReceiveDetails()
      },

      handleResetZoom () {
        this.chartInfo.zoomOut()
      },

      handleChangeSelectedRange (value) {
        this.selectedRange = value
        switch (value) {
          case 1:
            this.rangeType = 'type=today'
            break

          case 2:
            this.rangeType = 'type=last-week'
            break

          case 3:
            this.rangeType = 'type=last-year'
            break
        }
        this.getReceiveDetails()
      }
    },
  }
</script>

<style lang="scss">
  $primary: #48B4E0;

  .main-chart {
    height: 100%;
    position: relative;
    padding-top: 60px;
    box-shadow: 0 0 10px -5px;
    border-radius: 1rem;
    background-color: #fff;
    overflow: hidden;

    #mainChart {
      height: 100%;

      .highcharts-axis-title {
        width: unset !important;
        white-space: nowrap !important;
      }
    }

    .filter-section {
      position: absolute;
      top: 5px;
      right: 5px;

      .switch-options {
        .switch-item {
          color: #cdcdcd;
          background-color: inherit;
          border: 0;
          border-radius: 0.5rem;
          padding: 5px 10px;
          margin: 0 5px;
          font-size: 12px;

          &:hover {
            cursor: pointer;
            background-color: #f0f0f0;
          }

          &.active {
            color: #000000;
            font-weight: bold;
            background-color: #f0f0f0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .chart-type {
      position: absolute;
      top: 0;
      left: 5px;

      .switch-options {
        .switch-item {
          color: #cdcdcd;
          background-color: transparent;
          border: 0;
          padding: 5px 10px 15px;
          font-size: 18px;

          &:hover {
            cursor: pointer;
            color: #000000;
          }

          &.active {
            color: #000000;
            font-weight: bold;
            position: relative;

            &:after {
              content: ' ';
              width: 20px;
              height: 5px;
              background-color: #48B4E0;
              display: block;
              margin: auto;
              border-radius: 0.5rem;
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
            }
          }
        }
      }
    }

    &.mobile {
      .filter-section {
        .switch-options {
          .switch-item {
            font-size: 12px;
            padding: 5px;
            margin: 0;
          }
        }
      }

      .chart-type {
        .switch-options {
          .switch-item {
            font-size: 14px;
            padding: 10px 5px;
          }
        }
      }
    }

    .reset-zoom-btn {
      position: absolute;
      bottom: 5px;
      left: 5px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #cecece;
      cursor: pointer;
    }
  }
</style>
