<template>
  <div v-if="topCustomers.length > 0" class="top-customers" :class="isMobile ? 'mobile' : ''">
    <h3 class="header">
      {{ $t('report.tops.customer.title') }}
    </h3>
    <div class="list-header">
      <span class="row-number">
      {{ $t('report.tops.customer.table.header.row') }}
      </span>
      <span class="name">
      {{ $t('report.tops.customer.table.header.name') }}
      </span>
      <span v-if="!isMobile" class="phone-number">
      {{ $t('report.tops.customer.table.header.phoneNumber') }}
      </span>
      <span class="price">
      {{ $t('report.tops.customer.table.header.price') }}
      </span>
      <span v-if="isMobile" class="show">
      {{ $t('report.tops.customer.table.header.show') }}
      </span>
    </div>
    <ul class="customers-list">
      <template v-for="(customer, key) in topCustomers">
        <li :key="key" class="customer" @click="handleShowUser(customer)">
          <span class="row-number">
            {{ key + 1 }}
          </span>
          <span class="name">{{ customer.name }}</span>
          <span v-if="!isMobile" class="phone-number">{{ customer.phoneNumber }}</span>
          <span class="price">
            {{ customer.finalPrice }}
            <span class="currency">
              {{ $t('currency') }}
            </span>
          </span>
          <span v-if="isMobile" class="show">
            <custom-icon icon="EYE" height="20px" width="20px" />
          </span>
        </li>
      </template>
    </ul>


    <!-- show user details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showUserPromptStatus"
      @close="showUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="editUserPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('report.tops.customer.labels.profile') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-profile :user-id="selectedUser.id"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </div>
</template>

<script>
  import {getTopCustomers} from "../../../../http/requests/reports";
  import {addComma, getAvatarUrl, getStaticFileUrl} from "../../../../assets/js/functions";
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import UserProfile from "../../customers/profile/userProfile";

  export default {
    name: "topCustomers",
    components: {UserProfile, CustomIcon},
    data () {
      return {
        showUserPromptStatus: false,
        selectedUser: {},
        topCustomers: []
      }
    },
    computed: {
      isMobile() {
        return this.$store.state.windowWidth <= this.$store.state.mobileScreen
      },
    },
    created () {
      this.getTopCustomers()
    },
    methods: {
      getTopCustomers () {
        getTopCustomers().then((response) => {
          const topCustomers = response.data.data
          topCustomers.forEach((item) => {
            this.topCustomers.push({
              id: item.user.id,
              name: `${item.user.name || ''} ${item.user.family || ''}`,
              phoneNumber: item.user.phone_number,
              avatar: item.user.avatar ? getAvatarUrl(item.user.avatar) : item.user.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
              finalPrice: addComma(item.final_price)
            })
          })
        })
      },
      handleShowUser (user) {
        this.selectedUser = user
        this.showUserPromptStatus = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-customers {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc((100vh - 135px) / 2);
    min-height: 250px;
    box-shadow: 0 0 10px -5px;
    border-radius: 1rem;
    overflow: hidden;


    .header {
      position: relative;
      font-size: 18px;
      text-align: left;
      padding: 10px 15px 17px;
      margin-bottom: 10px;
      height: 45px;

      &:after {
        content: ' ';
        width: 50px;
        height: 5px;
        background-color: #48B4E0;
        display: block;
        margin: auto;
        border-radius: 0.5rem;
        position: absolute;
        bottom: 0;
        left: 15px;
      }
    }

    .list-header {
      display: flex;
      align-items: center;
      color: #cdcdcd;
      border-bottom: 1px solid #cdcdcd;
      margin: 0 15px;
      height: 40px;
      font-weight: 500;

      .row-number {
        width: 50px;
        text-align: center;
      }

      .image {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 45px;
      }

      .name {
        display: block;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc((100% - 50px) / 3);
      }

      .phone-number {
        display: block;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc((100% - 50px) / 3);
      }

      .price {
        text-align: center;
        width: calc((100% - 50px) / 3);
      }
    }

    .customers-list {
      height: calc(100% - 45px);
      padding: 0 15px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 8px;
        background: #f9f9fd;
        right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }

      .customer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 5px;
        color: #000000;
        border-bottom: 1px solid #cecece;
        font-size: 14px;
        height: 45px;


        &:hover {
          cursor: pointer;
        }

        .row-number {
          width: 50px;
          text-align: center;
        }

        .user-avatar {
          height: 35px;
          width: 35px;
          border-radius: 100%;
          overflow: hidden;

          .avatar {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .name {
          display: block;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc((100% - 50px) / 3);
        }

        .phone-number {
          direction: rtl;
          display: block;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc((100% - 50px) / 3);
        }

        .price {
          width: calc((100% - 50px) / 3);

          .currency {
            margin-left: 5px;
            font-size: 11px;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &.mobile {

      .list-header {
        .row-number {
          width: 40px;
          text-align: center;
        }

        .name {
          width: calc((100% - 90px) / 2);
        }

        .price {
          width: calc((100% - 90px) / 2);
        }

        .show {
          width: 50px;
        }
      }

      .customer {
        .row-number {
          width: 40px;
          text-align: center;
        }

        .name {
          width: calc((100% - 90px) / 2);
        }

        .price {
          width: calc((100% - 90px) / 2);
        }

        .show {
          display: flex;
          align-items: center;
          justify-content: end;
          width: 50px;
          text-align: center;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
</style>
